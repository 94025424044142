.App {
    display: flex;
    justify-content: center;
}

.infoBox {
    background: rgba(243, 235, 228, 0.71);
    padding: 16px;
    margin-top: 32px;
}

.infoBox > p {
    text-align: center;
}